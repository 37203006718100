












import { Component, Model, Prop, Vue } from 'vue-property-decorator';


// region Local types
type CharType = 'minus-sign' | 'digit' | 'dot';
type Formatter = (value: number | null) => string | null;
type Parser = (value: string | null) => number | null;
interface FormatAdapter {
    readonly formatter: Formatter;
    readonly parser: Parser;
    readonly fractionalSeparator: string;
}
// endregion


const modelChangeEvent = 'change';

const noModelValue = Symbol('modules/budget/staffing-table/NumberInput :: no value');
Object.freeze(noModelValue);


// region Utils
const getCharType = (fractionalSeparatorChar: string, value: string): (CharType | null) => {
    switch (value) {
        case '-':
            return 'minus-sign';

        case fractionalSeparatorChar:
            return 'dot';

        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
            return 'digit';

        default:
            return null;
    }
};

const getCharTypesBeforePosition = (fractionalSeparatorChar: string, value: string, position: number): Array<CharType> => {
    const result: Array<CharType> = [];

    const count = Math.min(value.length, position);
    for (let index = 0; index < count; index++) {
        const char = value[index];
        const type = getCharType(fractionalSeparatorChar, char);
        if (type !== null) {
            result.push(type);
        }
    }

    return result;
};

const createFormatter = (locale: string, minFractionalDigits?: number, maxFractionalDigits?: number): { readonly formatter: Formatter, readonly fractionalSeparator: string } => {
    const preparedLocale = locale.trim().toLocaleLowerCase();

    const format = new Intl.NumberFormat(preparedLocale, {
        minimumFractionDigits: minFractionalDigits,
        maximumFractionDigits: maxFractionalDigits
    });

    const formatter = (value: number | null): string | null => {
        if ((value === null) || (!value.isFinite)) {
            return null;
        }
        return format.format(value);
    };

    const fractionalSeparator = ((): string => {
        const formattedValue = format.format(1000);
        if (formattedValue.length === 5) {
            return formattedValue[1];
        } else {
            return '';
        }
    })();

    return { formatter, fractionalSeparator };
};

const createParser = (locale: string): Parser => {
    // noinspection DuplicatedCode
    const preparedLocale = locale.trim().toLocaleLowerCase();

    const fractionalSeparator = ((): string => {
        let result = '.';
        if ((preparedLocale === 'kk') || (preparedLocale === 'ru')) {
            result = ',';
        }
        return result;
    })();

    return (value: string | null): number | null => {
        if ((value === null) || (value.length === 0)) {
            return null;
        }

        let numberStarted = false;
        let negative = false;
        let fractionalStarted = false;
        let preparedValue = '';

        let commaCount = 0;
        let dotCount = 0;
        for (const char of value) {
            switch (char) {
                case '.':
                    dotCount++;
                    break;
                case ',':
                    commaCount++;
                    break;
                default:
                    break;
            }
        }

        let usedFractionalSeparator = fractionalSeparator;
        if ((dotCount > 0) && (commaCount === 0)) {
            // только точки - похоже на 123456987.987 (дробь, программирование) или 123,456,987.987 (дробь, региональные настройки для английского языка)
            usedFractionalSeparator = '.';
        } else {
            // если только запятые - похоже на 123,456,987 (целое число, региональные настройки для английского языка) - лучше ничего не делать
        }

        for (const char of value) {
            switch (char) {
                case '-':
                    if ((!numberStarted) && (!negative)) {
                        negative = true;
                    }
                    break;
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    if (!numberStarted) {
                        numberStarted = true;
                    }
                    preparedValue += char;
                    break;
                case usedFractionalSeparator:
                    if (!numberStarted) {
                        numberStarted = true;
                        preparedValue += '0.';
                    } else if (!fractionalStarted) {
                        fractionalStarted = true;
                        preparedValue += '.';
                    }
                    break;
                default:
                    // все остальные символы игнорируются
                    break;
            }
        }

        if (!numberStarted) {
            return null;
        }

        if (negative) {
            preparedValue = '-' + preparedValue;
        }

        if (preparedValue.endsWith('.')) {
            preparedValue += '0';
        }

        return parseFloat(preparedValue);
    };
};

const createFormatAdapter = (locale: string, minFractionalDigits?: number, maxFractionalDigits?: number): FormatAdapter => {
    const formatObject = createFormatter(locale, minFractionalDigits, maxFractionalDigits);
    const parser = createParser(locale);
    const fractionalSeparator = formatObject.fractionalSeparator;
    return { formatter: formatObject.formatter, parser, fractionalSeparator };
};

const formatAdapterMap = new Map<string, FormatAdapter>();
// endregion


@Component
export default class NumberInput extends Vue {
    // region Model, properties
    @Model(modelChangeEvent, {
        type: Number,
        required: false,
        default: noModelValue
    })
    public readonly value!: number | null | symbol;

    @Prop({
        type: Boolean,
        required: false,
        default: () => false,
    })
    public readonly!: boolean;

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly min!: string | number | null;

    public get usedMin(): number | null {
        const min = this.min;
        if (min === null) {
            return null;
        }

        if (typeof min === 'number') {
            if (min.isFinite) {
                return min;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(min);
        } catch (e) {
            console.error('components/custom/NumberInput.vue', e);
            result = null;
        }
        return result;
    }

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly max!: string | number | null;

    public get usedMax(): number | null {
        const max = this.max;
        if (max === null) {
            return null;
        }

        if (typeof max === 'number') {
            if (max.isFinite) {
                return max;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(max);
        } catch (e) {
            console.error('components/custom/NumberInput.vue', e);
            result = null;
        }
        return result;
    }

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public placeholder!: string | null;
    // endregion


    // region Lifecycle
    protected created() {
        this.setLocalValueFromValue();
        this.setFieldStringValueFromLocal();

        this.$watch('value', () => {
            this.setLocalValueFromValue();
        });

        this.$watch('fieldNumberValue', () => {
            if (this.localValue !== this.fieldNumberValue) {
                this.localValue = this.fieldNumberValue;
            }
        });

        this.$watch('localValue', () => {
            if (this.localValue !== null) {
                const min = this.usedMin;
                const max = this.usedMax;
                if ((min === null) || (max === null) || (min <= max)) {
                    if ((min !== null) && (this.localValue < min)) {
                        this.$nextTick(() => {
                            this.localValue = min;
                        });
                        return;
                    }
                    if ((max !== null) && (this.localValue > max)) {
                        this.$nextTick(() => {
                            this.localValue = max;
                        });
                        return;
                    }
                }
            }

            this.setFieldStringValueFromLocal();

            if (this.value !== this.localValue) {
                this.$emit(modelChangeEvent, this.localValue);
            }
        });
    }
    // endregion


    // region Formats
    public get formatAdapter(): FormatAdapter {
        const locale = this.$i18n.locale.trim().toLowerCase();

        const saved = (formatAdapterMap.get(locale) as unknown as (FormatAdapter | undefined));
        if (saved !== undefined) {
            return saved;
        }

        const created = createFormatAdapter(locale, 0, 15);
        formatAdapterMap.set(locale, created);
        return created;
    }

    public get formatter(): Formatter {
        return this.formatAdapter.formatter;
    }

    public get parser(): Parser {
        return this.formatAdapter.parser;
    }

    public get fractionalSeparator(): string {
        return this.formatAdapter.fractionalSeparator;
    }
    // endregion


    // region HTML element utils
    public get element(): HTMLInputElement | null {
        const elementComponent = (this.$refs.element as unknown);
        if (elementComponent instanceof Object) {
            const element = (elementComponent as Vue).$el;
            if (element instanceof HTMLInputElement) {
                return element;
            }
        }
        return null;
    }

    public useElement(use: (element: HTMLInputElement) => void) {
        const element = this.element;
        if (element !== null) {
            use(element);
        }
    }
    // endregion


    // region Local value
    public fieldStringValue = '';

    public get fieldNumberValue(): number | null {
        const formatter = this.formatter;
        const parser = this.parser;

        return parser(formatter(parser(this.fieldStringValue)));
    }

    public localValue: number | null = null;

    public setLocalValueFromValue() {
        // noinspection SuspiciousTypeOfGuard
        if ((typeof this.value !== 'symbol') && (this.localValue !== this.value)) {
            this.localValue = this.value;
        }
    }

    public setFieldStringValueFromLocal() {
        const value = ((this.localValue !== null) ? this.formatter(this.localValue) || '' : '');
        if (this.fieldStringValue !== value) {
            this.fieldStringValue = value;
        }
    }

    public onFieldStringValueChange(newValue: unknown) {
        let typedNewValue: string;
        if (typeof newValue === 'string') {
            typedNewValue = newValue;
        } else {
            typedNewValue = String(newValue);
        }

        const charsBeforeSelection = ((): Array<CharType> | null => {
            let result: Array<CharType> | null = null;

            this.useElement((element) => {
                const selectionStart = (element.selectionStart as unknown as (number | null));
                if (typeof selectionStart === 'number') {
                    const value = (element.value as unknown);
                    if ((typeof value === 'string') && (selectionStart < value.length)) {
                        result = getCharTypesBeforePosition(this.fractionalSeparator, value, selectionStart);
                    }
                }
            });

            return result;
        })();

        this.fieldStringValue = typedNewValue;

        if (charsBeforeSelection !== null) {
            setTimeout(() => {
                this.useElement((element) => {
                    if (charsBeforeSelection.length === 0) {
                        element.selectionDirection = 'forward';
                        element.selectionStart = 0;
                        element.selectionEnd = element.selectionStart;
                    } else {
                        const value = (element.value as unknown);
                        if (typeof value === 'string') {
                            if (value.length > 0) {
                                let valueIndex = 0;

                                while ((valueIndex < value.length) && (charsBeforeSelection.length > 0)) {
                                    const char = value.charAt(valueIndex);
                                    const charType = getCharType(this.fractionalSeparator, char);
                                    valueIndex++;

                                    if (charType === charsBeforeSelection[0]) {
                                        charsBeforeSelection.splice(0, 1);
                                    }
                                }

                                element.selectionDirection = 'forward';
                                element.selectionStart = Math.min(valueIndex, value.length);
                                element.selectionEnd = element.selectionStart;
                            } else {
                                element.selectionDirection = 'forward';
                                element.selectionStart = 0;
                                element.selectionEnd = element.selectionStart;
                            }
                        }
                    }
                });
            });
        }
    }

    public blur() {
        this.setFieldStringValueFromLocal();
    }
    // endregion
}
