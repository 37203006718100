

























































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import SvgIcon from './SvgIcon.vue';


const modelChangeEvent = 'change';


@Component({
    components: {
        SvgIcon,
    },
})
export default class CollapsiblePane extends Vue {
    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Boolean,
            required: false,
            default: null,
        },
    )
    public value!: boolean | null;

    @Prop({
        type: String,
        required: false,
        default: '',
    })
    public head!: string;

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public decorationVariant!: string | null;

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public contentOverflow!: string | null;
    // endregion


    // region Lifecycle
    public created() {
        // region Модель, свойства
        this.$watch('value', (/* value: boolean | null */) => {
            this.updateLocalValueFromValue();
        });
        // endregion

        // region Локальное состояние
        this.$watch('localValue', (localValue: boolean) => {
            if (this.value !== localValue) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    public mounted() {
        this.updateLocalValueFromValue();
    }
    // endregion


    // region Утилиты
    public collapseExpandIcon = 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z';
    // endregion


    // region Локальное состояние
    public localValue = false;

    public updateLocalValueFromValue() {
        if ((this.value !== null) && (this.localValue !== this.value)) {
            this.localValue = this.value;
        }
    }

    public onCollapseExpandButtonClick() {
        this.localValue = !this.localValue;
    }
    // endregion


    // region Анимации
    public onBeforeEnter(el: HTMLElement) {
        el.style.height = '0';
    }

    public onEnter(el: HTMLElement) {
        setTimeout(() => {
            el.style.height = `${el.scrollHeight}px`;
        });
    }

    public onAfterEnter(el: HTMLElement) {
        el.style.height = '';
    }

    public onBeforeLeave(el: HTMLElement) {
        el.style.height = `${el.offsetHeight}px`;
    }

    public onLeave(el: HTMLElement) {
        setTimeout(() => {
            el.style.height = '0';
        });
    }

    public onAfterLeave(el: HTMLElement) {
        el.style.height = '';
    }
    // endregion
}
