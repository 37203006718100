











































import { Component, Prop, Vue } from 'vue-property-decorator';
import FlatButton from './FlatButton.vue';
import svgIcons from './svg-icons';
import SvgIcon from './SvgIcon.vue';
import { TreeItem } from './tree-controller';


const icons = {
    chevron: svgIcons.mdi['chevron-right'],
    loading: svgIcons.mdi['dots-circle'],
};


@Component({
    components: {
        'flat-button': FlatButton,
        'svg-icon': SvgIcon,
    },
})
export default class TreeCell extends Vue {
    // region Model, properties
    @Prop({
        type: Object,
        required: true,
    })
    public item!: TreeItem<unknown>;

    @Prop({
        type: Number,
        required: false,
        default: 16,
    })
    public levelMarginMultiplier!: number;

    @Prop({
        type: String,
        required: false,
        default: 'px',
    })
    public levelMarginUnit!: string;
    // endregion


    // noinspection JSUnusedLocalSymbols
    private icons = icons;


    private get levelMargin(): number { return this.levelMarginMultiplier * this.item.level; }

    private get levelMarginStyleValue(): string { return `${this.levelMargin}${this.levelMarginUnit}`; }


    private onToggleButtonClick() {
        const item = this.item;
        item.controller.toggleItem(item);
    }
}
